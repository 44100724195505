var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-card-title', [_vm._v(" Calculation Logic tests ")]), _c('v-card-text', [_c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Select Shop",
      "items": _vm.shops,
      "item-text": "text",
      "item-value": "id"
    },
    model: {
      value: _vm.shopId,
      callback: function callback($$v) {
        _vm.shopId = $$v;
      },
      expression: "shopId"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', [_vm._v("Select Date")]), _c('v-date-picker', {
    model: {
      value: _vm.date,
      callback: function callback($$v) {
        _vm.date = $$v;
      },
      expression: "date"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    attrs: {
      "primary": "",
      "disabled": !_vm.shopId
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("Submit")])], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "display-2 text-center"
  }, [_vm._v("Daily")])]), _c('DailyCalculationSummary'), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "display-2 text-center"
  }, [_vm._v("Cumulated")])]), _c('CumulatedCalculationSummary')], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }