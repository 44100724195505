<template>
  <v-card>
    <v-card-title>
      Calculation Logic tests
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-select
              label="Select Shop"
              :items="shops"
              item-text="text"
              item-value="id"
              v-model="shopId"
            >
            </v-select>
          </v-col>

          <v-col cols="12">
            <div>Select Date</div>
            <v-date-picker v-model="date"> </v-date-picker>
          </v-col>

          <v-col cols="12">
            <v-btn primary @click="submit" :disabled="!shopId" >Submit</v-btn>
          </v-col>

          <v-col cols="12">
            <div class="display-2 text-center">Daily</div>
          </v-col>

          <DailyCalculationSummary />

          <v-col cols="12">
            <div class="display-2 text-center">Cumulated</div>
          </v-col>

          <CumulatedCalculationSummary />
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import dayjs from "@/plugins/dayjs";
import { mapGetters } from "vuex";
import DailyCalculationSummary from "@/views/test/DailyCalculationSummary";
import CumulatedCalculationSummary from "@/views/test/CumulatedCalculationSummary";
export default {
  name: "Index",
  components: {CumulatedCalculationSummary, DailyCalculationSummary },
  computed: {
    ...mapGetters(["getShops"]),
    shops() {
      return this.getShops.map(item => {
        return {
          id: item.id,
          text: `ID: ${item.id} | Name: ${item.store_display_name}`
        };
      });
    }
  },
  data() {
    return {
      loading: false,
      shopId: null,
      date: dayjs().format("YYYY-MM-DD")
    };
  },
  created() {
    this.getDataFromApi();
  },
  methods: {
    async getDataFromApi() {
      await this.$store.dispatch("SHOP_GET_ALL");
    },
    submit() {
      if (!this.shopId) return;
      this.loading = true;
      let params = {
        shop_id: this.shopId,
        date: this.date
      };

      this.$store.dispatch("CALCULATION_TEST_GET", params).finally(() => {
        this.loading = false;
      });
    }
  }
};
</script>

<style scoped></style>
