<template>
  <div v-frag>
    <v-col cols="12" class="headline">Sales</v-col>
    <template v-if="!lunchReport && !dinnerReport && !allDayReport">
      <v-col cols="12">
        No report for this date.
      </v-col>
    </template>

    <template v-if="lunchReport">
      <v-col cols="12">Lunch</v-col>
      <v-col cols="12">
        <v-row no-gutters>
          <v-col v-for="field in salesFields" :key="`lunch-${field}`" cols="12">
            Lunch {{ field }}:
            <template v-if="field === 'weather'">
              {{ weathers[lunchReport[field] - 1] }}
            </template>
            <template v-else-if="field.startsWith('number')">
              {{ lunchReport[field] }}
            </template>
            <template v-else>
              {{ lunchReport[field] | formatMoney }}
            </template>
          </v-col>
        </v-row>
      </v-col>
    </template>

    <template v-if="dinnerReport">
      <v-col cols="12">Dinner</v-col>
      <v-col cols="12">
        <v-row no-gutters>
          <v-col
            v-for="field in salesFields"
            :key="`dinner-${field}`"
            cols="12"
          >
            Dinner {{ field }}:
            <template v-if="field === 'weather'">
              {{ weathers[dinnerReport[field] - 1] }}
            </template>
            <template v-else-if="field.startsWith('number')">
              {{ dinnerReport[field] }}
            </template>
            <template v-else>
              {{ dinnerReport[field] | formatMoney }}
            </template>
          </v-col>
        </v-row>
      </v-col>
    </template>

    <template v-if="allDayReport">
      <v-col cols="12">All-Day Report</v-col>
      <v-col cols="12">
        <v-row no-gutters>
          <v-col
            v-for="field in salesFields"
            :key="`all-day-${field}`"
            cols="12"
          >
            All-Day Report {{ field }}:
            <template v-if="field === 'weather'">
              <!--              {{ weathers[dinnerReport[field]] }}-->
            </template>
            <template v-else-if="field.startsWith('number')">
              {{ allDayReport[field] }}
            </template>
            <template v-else>
              {{ allDayReport[field] | formatMoney }}
            </template>
          </v-col>
        </v-row>
      </v-col>
    </template>
    <v-col cols="12" class="headline">Costs</v-col>
    <v-col cols="12">
      <template v-if="dailyCosts">
        <v-row no-gutters>
          <v-col v-for="field in costsFields" :key="`cost-${field}`" cols="12">
            {{ field }}:
            <template>
              {{ dailyCosts[field] | formatMoney }}
            </template>
          </v-col>
        </v-row>
      </template>
      <template v-else>
        No report for this date.
      </template>
    </v-col>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "DailyCalculationSummary",
  filters: {
    formatMoney(value) {
      if (!Number.isInteger(value)) return value;
      const money = new Intl.NumberFormat("ja-JP", {
        style: "currency",
        currency: "JPY"
      });
      return money.format(value);
    }
  },
  computed: {
    ...mapGetters(["getTestCalc"]),
    dailySales() {
      return this.getTestCalc.daily.sales.map(report => {
        let foodDrinkSales =
          report.food_sales + report.drink_sales + report.others_sales;
        let sales =
          foodDrinkSales + report.delivery_sales + report.takeout_unit_sales;
        return {
          ...report,
          sales: sales,
          average_customer_spend: Math.round(
            sales / report.number_of_total_customer
          ),
          average_in_shop_spend: Math.round(
            foodDrinkSales / report.number_of_customer_store
          )
        };
      });
    },
    lunchReport() {
      return this.dailySales.find(item => item.lunch_or_dinner === 1);
    },
    dinnerReport() {
      return this.dailySales.find(item => item.lunch_or_dinner === 2);
    },
    allDayReport() {
      if (!this.lunchReport || !this.dinnerReport) return null;
      let obj = Object.entries(this.dinnerReport).reduce(
        (acc, [key, value]) => ({ ...acc, [key]: (acc[key] || 0) + value }),
        { ...this.lunchReport }
      );

      obj.average_customer_spend = Math.round(obj.sales / obj.number_of_total_customer);
      obj.average_in_shop_spend = Math.round((obj.food_sales + obj.drink_sales + obj.others_sales) / obj.number_of_customer_store);

      return obj;
    },
    dailyCosts() {
      return this.getTestCalc.daily.costs;
    }
  },
  data() {
    return {
      weathers: ["晴れ", "曇り", "雨"],
      salesFields: [
        "food_sales",
        "drink_sales",
        "delivery_sales",
        "takeout_unit_sales",
        "others_sales",
        "sales",
        "number_of_delivery",
        "number_of_takeout",
        "number_of_customer_store",
        "number_of_total_customer",
        "number_of_group_store",
        "number_of_total_group",
        "weather",
        "average_customer_spend",
        "average_in_shop_spend"
      ],
      costsFields: [
        "labor_cost",
        "foodstuff_cost",
        "rent_cost",
        "platform_cost",
        "others_cost",
        "all_cost"
      ]
    };
  }
};
</script>

<style scoped></style>
