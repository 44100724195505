var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "frag",
      rawName: "v-frag"
    }]
  }, [_c('v-col', {
    staticClass: "headline",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v("Sales")]), !_vm.lunchReport && !_vm.dinnerReport && !_vm.allDayReport ? [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" No report for this date. ")])] : _vm._e(), _vm.lunchReport ? [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm._v("Lunch")]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, _vm._l(_vm.salesFields, function (field) {
    return _c('v-col', {
      key: "lunch-".concat(field),
      attrs: {
        "cols": "12"
      }
    }, [_vm._v(" Lunch " + _vm._s(field) + ": "), field === 'weather' ? [_vm._v(" " + _vm._s(_vm.weathers[_vm.lunchReport[field] - 1]) + " ")] : field.startsWith('number') ? [_vm._v(" " + _vm._s(_vm.lunchReport[field]) + " ")] : [_vm._v(" " + _vm._s(_vm._f("formatMoney")(_vm.lunchReport[field])) + " ")]], 2);
  }), 1)], 1)] : _vm._e(), _vm.dinnerReport ? [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm._v("Dinner")]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, _vm._l(_vm.salesFields, function (field) {
    return _c('v-col', {
      key: "dinner-".concat(field),
      attrs: {
        "cols": "12"
      }
    }, [_vm._v(" Dinner " + _vm._s(field) + ": "), field === 'weather' ? [_vm._v(" " + _vm._s(_vm.weathers[_vm.dinnerReport[field] - 1]) + " ")] : field.startsWith('number') ? [_vm._v(" " + _vm._s(_vm.dinnerReport[field]) + " ")] : [_vm._v(" " + _vm._s(_vm._f("formatMoney")(_vm.dinnerReport[field])) + " ")]], 2);
  }), 1)], 1)] : _vm._e(), _vm.allDayReport ? [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm._v("All-Day Report")]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, _vm._l(_vm.salesFields, function (field) {
    return _c('v-col', {
      key: "all-day-".concat(field),
      attrs: {
        "cols": "12"
      }
    }, [_vm._v(" All-Day Report " + _vm._s(field) + ": "), field === 'weather' ? void 0 : field.startsWith('number') ? [_vm._v(" " + _vm._s(_vm.allDayReport[field]) + " ")] : [_vm._v(" " + _vm._s(_vm._f("formatMoney")(_vm.allDayReport[field])) + " ")]], 2);
  }), 1)], 1)] : _vm._e(), _c('v-col', {
    staticClass: "headline",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v("Costs")]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.dailyCosts ? [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, _vm._l(_vm.costsFields, function (field) {
    return _c('v-col', {
      key: "cost-".concat(field),
      attrs: {
        "cols": "12"
      }
    }, [_vm._v(" " + _vm._s(field) + ": "), [_vm._v(" " + _vm._s(_vm._f("formatMoney")(_vm.dailyCosts[field])) + " ")]], 2);
  }), 1)] : [_vm._v(" No report for this date. ")]], 2)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }